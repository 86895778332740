<template>
  <aside class="sidebar" v-bind:class="{ wide: tab2==1 }">
    <v-btn
      class="mx-2 sidebarToggle"
      :class="{ expanded: expanded }"
      fab
      dark
      small
      color="primary"
      @click="$emit('sidebarToggleClick')"
    >
      <v-icon v-if="expanded" dark>
        mdi-chevron-right
      </v-icon>
      <v-icon v-else dark>
        mdi-menu
      </v-icon>
    </v-btn>
    <v-tabs-items v-model="tab2">
      <v-tab-item class="tabsContainer">
        <v-toolbar
          dark
          color="primary"
          flat
        >
          <v-tabs
            v-model="tab1"
            grow
          >
            <v-tab>
              Liste
            </v-tab>
            <v-tab>
              Filter
            </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items class="tabs" v-model="tab1">
          <v-tab-item class="listItems">
            <slot name="list"></slot>
          </v-tab-item>
          <v-tab-item class="filterItems">
            <slot name="filter"></slot>
          </v-tab-item>
        </v-tabs-items>
      </v-tab-item>
      <v-tab-item class="singleItem">
        <v-btn
          class="backBtn"
          text
          small
          elevation="0"
          @click="tab2=0"
        >
        <v-icon
          small
        >
          $marker
        </v-icon>
        Zurück zur Übersicht
        </v-btn>
        <slot name="singleEntry"></slot>
      </v-tab-item>
    </v-tabs-items>
  </aside>
</template>

<script>
export default {
  name: "Sidebar",
  props: ['expanded'],
  data () {
    return {
      tab1: 0,
      tab2: 0,
    }
  },
  methods: {
    showDetail: function(){this.tab2 = 1},
  },
};
</script>

<style lang="scss">
.sidebar {
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
  .sidebarToggle {
    position: absolute;
    right: -60px;
    .v-icon {
      transition: all .3s;
    }
    &.expanded .v-icon {
      transform:rotate(180deg);
    }
  }
  .v-window__container,
  .v-window-item {
    height:100%;
  }
  .tabs {
    .v-window-item {
      padding:10px;
    }
    .v-window-item {
      overflow-y:auto;
      overflow-x:hidden
    }
    .listItems {
      .v-card {
        margin-bottom: 10px;
      }
    }
  }
  .backBtn {
    position: relative;
    left: -25px;
    padding: 0 8px;
    &:hover {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .singleItem {
    padding: 10px 25px;
    overflow-y: auto;
    .v-btn {
      margin-bottom: 20px;
    }
  }
  .tabsContainer {
    display: flex;
    flex-direction: column;
  }
}

</style>
