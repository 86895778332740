<template>
  <v-app>
    <Sidebar 
      :expanded="sidebar_expanded"
      :class="{hidden: !sidebar_expanded}"
      ref="sidebar" 
      id="sidebar"
      @sidebarToggleClick="toggleSidebar"
    >
      <template v-slot:list>
        <v-card
          elevation="1"
          tile
          v-for="l in founders_filtered"
          :key="l.id"
          @click="showDetail(l)"
        >
          <v-img
            v-if="l.entryData.logoImage" :src="l.entryData.logoImage.sourceUrl" 
            height="60"
            contain
          ></v-img>
          <v-card-title>{{ l.title }}</v-card-title>
          <v-card-text>
            <div class="fieldsAndIndustries">
              <span v-for="f in l.fields" :key="f.id">{{ f.name }}</span>
              <span v-for="i in l.industries" :key="i.id">{{ i.name }}</span>
            </div>
            <p>{{ l.addressData.postalCode }} {{ l.addressData.city }}</p>
            </v-card-text>
        </v-card>
      </template>
      <template v-slot:filter>
        <v-autocomplete
          v-model="filter_industries"
          :items="industries"
          item-text="name"
          item-value="id"
          small-chips
          clearable
          label="Branche"
        ></v-autocomplete>
        <v-autocomplete
          v-model="filter_fields"
          :items="fields"
          item-text="name"
          item-value="id"
          small-chips
          clearable
          label="Fachgebiet"
        ></v-autocomplete>
        <v-autocomplete
          v-model="filter_states"
          :items="states"
          small-chips
          clearable
          label="Provinz"
        ></v-autocomplete>
        <v-autocomplete
          v-model="filter_cities"
          :items="cities"
          small-chips
          clearable
          label="Stadt"
        ></v-autocomplete>
        
        <h3 style="margin-bottom:40px">Gründungsjahr</h3>
        <v-range-slider
          v-model="filter_foundingdate"
          :max="foundingdate_max"
          :min="foundingdate_min"
          thumb-label="always"
          class="align-center"
        >
        </v-range-slider>
      </template>
      <template v-if="detailFounder !== null" v-slot:singleEntry>
        <h2>{{ detailFounder.title }}</h2>
        <div>
          <span v-if="detailFounder.entryData.foundingDate">Gründung {{ detailFounder.entryData.foundingDate }} | </span>
          <span class="fieldsAndIndustries">
            <span v-for="f in detailFounder.fields" :key="f.id">{{ f.name }}</span>
            <span v-for="i in detailFounder.industries" :key="i.id">{{ i.name }}</span>
          </span>
        </div>
        <div class="text1">{{ detailFounder.entryData.text1 }}</div>
        <v-img 
          v-if="detailFounder.entryData.additionalImage" 
          contain :src="detailFounder.entryData.additionalImage.sourceUrl" 
          max-width="300"
        />
        <div class="text2">{{ detailFounder.entryData.text2 }}</div>

        <div class="founders">
          <v-icon
            small
          >
            $bulb
          </v-icon>
          <div>GründerInnen:
            <span v-for="f in detailFounder.entryData.founders" :key="f.name">{{ f.name }}</span>
          </div>
        </div>
        <v-img 
          v-if="detailFounder.entryData.logoImage" 
          contain :src="detailFounder.entryData.logoImage.sourceUrl" 
          max-width="200"
        />
        <v-col v-if="detailFounder.entryData.website" class="text-right">
          <v-btn
            elevation="0"
            x-small
            :href="detailFounder.entryData.website" target="_blank"
          >
            Website
            <v-icon
              right
              dark
              x-small
            >
              mdi-open-in-new
            </v-icon>
          </v-btn>
        </v-col>
      </template>
    </Sidebar>
    <LMap ref="map" :zoom="6.5" :center="initialLocation" :options="{zoomControl: false, tap: false }">
      <LTileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"></LTileLayer>
      <LControlZoom position="bottomright"  ></LControlZoom>
      <LMarkerCluster
        :options="clusterOptions"
        @clusterclick="click"
      >
        <LMarker
          v-for="l in founders_filtered"
          :key="l.id"
          :lat-lng="[l.addressData.lat, l.addressData.long]"
        >
          <LPopup>
            <h5>{{ l.title }}</h5>
            <p>
              {{ l.addressData.street }} {{ l.addressData.streetNo }}<br>
              {{ l.addressData.postalCode }} {{ l.addressData.city }}
            </p>
            <v-btn
              block
              x-small
              elevation="0"
              @click="showDetail(l)"
            >mehr</v-btn>
          </LPopup>
        </LMarker>
        <LMarker
          :icon="cicon"
          :lat-lng="[51.3256, 9.5052]"
        >
          <LPopup>
            <h5>Science Park</h5>
            <p>Universitätsplatz 12, 34127 Kassel<br><a href="https://www.sciencepark-kassel.de" target="_blank">www.sciencepark-kassel.de</a></p>
          </LPopup>
        </LMarker>
      </LMarkerCluster>
    </LMap>
  </v-app>
</template>

<script>
import Sidebar from './components/Sidebar.vue'
// import L from 'leaflet';
import { latLng, Icon, icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LControlZoom } from "vue2-leaflet";
import Vue2LeafletMarkercluster from "vue2-leaflet-markercluster";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import gql from 'graphql-tag'
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  // iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconUrl: require('@/assets/bulb.svg'),
  iconRetinaUrl: require('@/assets/bulb.svg'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: "App",
  components: {
    Sidebar,
    LMap,
    LPopup,
    LTileLayer,
    LMarker,
    LControlZoom,
    LMarkerCluster: Vue2LeafletMarkercluster,
  },
  apollo: {
    industries: {
      query: gql`query {
        industries(first:1000) {
          edges {
            node {
              id
              name
              count
            }
          }
        }
      }`,
      update: (data) => {
        return data.industries.edges.map(f => f.node)
      }
    },
    fields: {
      query: gql`query {
        fields(first:1000) {
          edges {
            node {
              id
              name
              count
            }
          }
        }
      }`,
      update: (data) => {
        return data.fields.edges.map(f => f.node)
      }
    },
    founders: {
      query: gql`query {
        founders(first:1000) {
          edges {
            node {
              date
              title
              slug
              industries {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              fields {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              entryData {
                website
                logoImage {
                  sourceUrl
                }
                additionalImage {
                  sourceUrl
                }
                text1
                text2
                foundingDate
                founders {
                  name
                }
              }
              addressData {
                city
                country
                state
                lat
                long
                postalCode
                street
                streetNo
              }
              id
            }
          }
        }
      }`,
      update: function(data) {
        let f = data.founders.edges.map(f => { return JSON.parse(JSON.stringify(f.node)) })
        f.forEach((el) => {
          if(el.entryData.foundingDate !== null) {
            if(el.entryData.foundingDate < this.foundingdate_min) {
              this.foundingdate_min = this.filter_foundingdate[0] = el.entryData.foundingDate
            }
            if(el.entryData.foundingDate > this.foundingdate_max) {
              this.foundingdate_max = this.filter_foundingdate[1] = el.entryData.foundingDate
            }
          }
          if(el.fields) {
            el.fields = el.fields.edges.map(a => a.node)
          }
          if(el.industries) {
            el.industries = el.industries.edges.map(a => a.node)
          }
        })
        return f
      }
    },
  },
  methods: {
    click: (e) => e.layer.zoomToBounds({paddingTopLeft: [300, 0]}),
    showDetail: function (founder) {
      this.detailFounder = founder
      this.$refs.map.mapObject.fitBounds([[founder.addressData.lat, founder.addressData.long],[founder.addressData.lat, founder.addressData.long]], {animate: true, paddingTopLeft: [300,0]})
      this.$refs.sidebar.showDetail()
      if(!this.sidebar_expanded) {
        this.sidebar_expanded = true
      }
    },
    toggleSidebar: function() {
      this.sidebar_expanded = !this.sidebar_expanded
    }
  },
  computed: {
    founders_filtered() {
      let filtered = this.founders.filter((el) => {
        let keep = true

        // filter industries
        const industries_ids = el.industries.map(i => i.id)
        if(!industries_ids.includes(this.filter_industries) && this.filter_industries !== null) {
          keep = false
        }

        // filter fields
        const fields_ids = el.fields.map(i => i.id)
        if(!fields_ids.includes(this.filter_fields) && this.filter_fields !== null) {
          keep = false
        }

        // filter cities
        if(el.addressData.city !== this.filter_cities && this.filter_cities !== null) {
          keep = false
        }

        // filter states
        if(el.addressData.state !== this.filter_states && this.filter_states !== null) {
          keep = false
        }

        // filter foundingdate
        if(el.entryData.foundingDate !== null && (el.entryData.foundingDate < this.filter_foundingdate[0] || el.entryData.foundingDate > this.filter_foundingdate[1])) {
          keep = false
        }

        return keep
      })
      filtered.sort((a, b) => a.title.localeCompare(b.title))
      return filtered
    },
    cities() {
      if(this.founders.length) {
        return this.founders.map(f => f.addressData.city).filter(function(item, pos, self) {
          return  (item !== null && self.indexOf(item) == pos);
        }).sort()
      } else {
        return []
      }
    },
    states() {
      if(this.founders.length) {
        return this.founders.map(f => f.addressData.state).filter(function(item, pos, self) {
          return (item !== null && self.indexOf(item) == pos);
        }).sort()
      } else {
        return []
      }
    }
  },
  data() {
    let customicon = icon(
      Object.assign({}, Icon.Default.prototype.options, { iconUrl, shadowUrl })
    );
    return {
      founders_loaded: false,
      foundingdate_min: 2000,
      foundingdate_max: 10,
      sidebar_expanded: true,
      filter_foundingdate: [1980, 2020],
      filter_industries: null,
      filter_fields: null,
      filter_cities: null,
      filter_states: null,
      detailFounder: null,
      founders: [],
      fields: [],
      activeTab: 'list',
      industries: [],
      icon: customicon,
      clusterOptions: {
        zoomToBoundsOnClick: false
      },
      initialLocation: latLng(51.1642292, 10.4541194),
      cicon: icon({
        iconUrl: require('@/assets/marker.svg'),
        iconSize: [33, 49],
        iconAnchor: [16, 49],
        popupAnchor:  [1, -50]
      }),
    };
  },
  watch: {
    founders_filtered: function (val) {
      if(val.length && this.founders_loaded) {
        this.$refs.map.mapObject.fitBounds(val.map(l => { return [l.addressData.lat, l.addressData.long] }), {paddingTopLeft: [300,0]})
      }
      this.founders_loaded = true
    }
  },
  mounted() {
    if(this.$vuetify.breakpoint.width < 600) {
        this.sidebar_expanded = false
    }
    setTimeout(() => {
      this.$nextTick(() => {
        this.clusterOptions = { disableClusteringAtZoom: 11 };
      });
    }, 5000);
  }
}
</script>

<style lang="scss">
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
html,
body,
#app {
  height: 100%;
  margin: 0;
}
body {
  font-family: Tahoma;
}
#sidebar {
  position: absolute;
  box-shadow: 0 0 20px #0002;
  left:10px;
  top:10px;
  bottom:10px;
  width:250px;
  z-index: 401;
  transition: all .3s;
  font-size: .7rem;
  max-width: calc(100% - 80px);
  &.hidden {
    left: -250px;
    &.wide {
      left: -360px;
    }
  }
  &.wide {
    width: 360px;
  }
  .v-card__title {
    font-size: .7rem;
    line-height: 1.4rem;
    padding: 15px 16px 0px 16px;
  }
  .v-card__text {
    font-size: .7rem;
  }
  .v-tabs-slider-wrapper {
    color: black !important;
  }
  .filterItems {
    padding:25px;
  }
  .singleItem {
    h3 {
      margin-top: 20px;
    }
    .v-image {
      margin:25px 0;
    }
  }
  .text1 {
    margin: 15px 0;
    font-style: italic;
    font-size:.75rem;
    color: #666;
  }
  .text2 {
    margin: 15px 0;
  }
  .fieldsAndIndustries {
    span::after {
      content:', ';
    }
    span:last-child::after {
      content:'';
    }
  }
  .founders {
    display: flex;
    position: relative;
    left: -17px;
    align-items: center;
    span::after {
      content:', ';
    }
    span:last-child::after {
      content:'';
    }
  }
  .leaflet-popup-content {
    p {
      font-size: .65rem;
      margin: 10px 0;
    }
  }
}
</style>