import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import IconBulb from '@/components/IconBulb.vue'
import IconMarker from '@/components/IconMarker.vue'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#c7105c',
            },
        },
    },
    icons: {
        values: {
            bulb: {
                component: IconBulb
            },
            marker: {
                component: IconMarker
            },
        },
    },
});
