import Vue from 'vue'
import App from './App.vue'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import VueApollo from '@vue/apollo-option'
import vuetify from './plugins/vuetify'



const httpLink = createHttpLink({
  uri: 'https://gruendungslandkarte.de/index.php?graphql',
})
const cache = new InMemoryCache()
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})
Vue.use(VueApollo)
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})




Vue.config.productionTip = false

new Vue({
  apolloProvider,
  vuetify,
  render: h => h(App)
}).$mount('#app')
